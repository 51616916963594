import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxGroupComponent } from './checkbox-group.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { I18nModule } from '@spartacus/core';
import { GetVarModule } from '@util/directives/get-var/get-var.module';



@NgModule({
  declarations: [
    CheckboxGroupComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    I18nModule,
    GetVarModule
  ],
  exports: [
    CheckboxGroupComponent
  ]
})
export class CheckboxGroupModule { }
